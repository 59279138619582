.customContainer {
  background-color: #eaeaea;
  padding-bottom: 20px;
}
.backGround {
  background-color: white;
  position: sticky !important;
  top: 80px;
  z-index: 1;
  border-top: 1px solid var(--color-primary);
}
.containerX {
  margin: 0px auto;
}
.containerY {
  margin-bottom: 0px;
}

.companyProfileBackground {
  position: relative;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  color: white;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
}
.companyProfileBackgroundPhoto {
  width: 100%;
  height: 100%;

  position: absolute;
}
.complanyProfilePhotoRow {
  padding: 20px 0px;
}
.companyProfilePhoto {
  display: flex;
  align-items: center;
  padding: 20px 0px;
  margin: auto;
  border-radius: 12px;
  justify-content: center;
  z-index: 1;
}

.profileImg {
  width: 200px;
  height: 200px;
  border-radius: 12px;
  object-fit: cover;
  object-position: center;
}
.profileMainCol {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 21px;
  z-index: 1;
}
.companyName {
  font-size: 3rem;
  font-weight: 800;
}

.profileCol {
  font-size: 1.5rem;
}
.profilebtns {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 15px;
  padding: 50px;
  z-index: 1;
}
.borders {
  border-radius: 6px;
  background-color: white;
  margin: 10px;
}
.overview {
  padding: 0px;
}

.overviewCol {
  position: relative;
  font-weight: 500;
  padding: 20px;
}
.overviewCol2 {
  padding: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.ratingCol {
  position: relative;
  padding: 20px;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  line-height: 2.5rem;
  flex-wrap: wrap;
  margin: 10px;
}
.details {
  padding: 15px 20px;
  /* margin-left: 20px;
  margin-right: 0px;
  position: relative; */
}
.detailsHeading {
  margin: 0px;
}
.detail {
  padding-bottom: 10px;
}
.ratingDiv11 {
  font-size: 20px;
  font-weight: 700;
}
.ratingDiv21 {
  display: flex;
  gap: 10px;
  align-items: baseline;
}
.ratingDiv211 {
  font-size: 1rem;
  font-weight: 500;
  width: 160px;
}
.ratingDiv12 {
  font-size: 4rem;
  display: flex;
  gap: 10px;
  align-items: center;
}
.ratingDiv2 {
  display: flex;
  flex-direction: column;
  line-height: 2rem;
  justify-content: center;
}
.ratingDiv212 {
  font-size: 1rem;
  font-weight: 700;
}
.ratingDiv221 {
  font-size: 1rem;
  font-weight: 500;
  width: 160px;
  display: flex;
  align-items: baseline;
  gap: 3px;
}
.icon {
  width: 40px;
  transition: all 120ms ease-out;
}
.icon:hover {
  z-index: 2;
  transform: scale(1.2);
}
.iconDiv {
  display: flex;
  gap: 5px;
  padding-top: 10px;
}
.ratingDiv12 {
  font-size: 4rem;
  display: flex;
  gap: 10px;
  align-items: center;
}
.ratingDiv2 {
  display: flex;
  flex-direction: column;
  line-height: 2rem;
  justify-content: center;
}
.ratingDiv32 {
  font-size: 3rem;
}

.nameInDetailsInner {
  font-size: 3rem;
  font-weight: 500;
  padding: 0px;
  color: gray;
}
.allDetails {
  position: relative;
  color: gray;
  cursor: default;
  margin: 0px;
  gap: 30px;
}
.detailDiv {
  padding-bottom: 15px;
}
.detailHeading {
  font-size: 1.5rem;
}
.editIcon {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 1.5rem;
  display: flex;
  cursor: pointer;
  width: auto;
  padding: 0px;
  color: black;
}
.allStars {
  font-size: large;
}
.categories {
  display: inline-flex;
  margin-right: 10px;
  border: 1px solid var(--color-primary);
  border-radius: 20px;
  padding: 0px 5px;
  color: var(--color-primary);
  margin-bottom: 7px;
  align-items: center;
}
.categoryDelete {
  color: red;
  padding-left: 5px;
}
.awards {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}
.verificationDiv {
  padding: 20px;
  justify-content: space-between;
  align-items: center;
}
.floatingEye {
  position: absolute;
  top: 33px;
  right: 30px;
  font-size: 22px;
  color: #979797;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .profileImg {
    width: 200px;
    height: 200px;
  }
  .profileCol {
    font-size: 1rem;
  }
  .companyName {
    font-size: 1.5rem;
    font-weight: 100;
  }
  .tabs {
    margin: 0px;
    font-size: 0.8rem;
    flex-wrap: wrap;
  }
  .profileRow {
    text-align: center;
  }
  .allDetails {
    gap: 0px;
  }
  .verificationDiv {
    flex-direction: column;
  }
}
