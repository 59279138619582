.outerDiv {
  padding: 100px;
  margin: 60px 0px;
  background-color: rgb(128 217 90 / 10%);
  color: #69cf48;
}
.innerDiv {
  text-align: center;
  gap: 15px;
  font-size: 1.5rem;
}
.span {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}
.data1Div {
  font-size: 4rem;
}
