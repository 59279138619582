.customContainer {
  background-color: #eaeaea;
}
.backGround {
  background-color: white;
  position: sticky !important;
  top: 80px;
  z-index: 1;
  border-top: 1px solid var(--color-primary);
  border-bottom: 1px solid var(--color-primary);
}
.containerX {
  margin: 0px auto;
}
.containerY {
  padding: 0px;
  margin: 0px auto;
}

.companyProfileBackgroundPhoto {
  background-color: white;
}
.companyProfilePhoto {
  display: flex;
  align-items: center;
  padding: 20px 0px;
  margin: auto;
  border-radius: 12px;
  justify-content: center;
}
.profileImg {
  width: 200px;
  height: 200px;
  border-radius: 12px;
  object-fit: cover;
  object-position: center;
}
.profileMainCol {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 21px;
}

.companyName {
  font-size: 1.875rem;
  font-weight: 700;
}

.profileCol {
  font-size: 1.25rem;
}
.profilebtns {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 15px;
  padding: 50px;
}
.reviewHeading {
  font-size: 1.875rem;
  font-weight: 500;
  background-color: white;
  border-radius: 6px;
  padding: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  padding-left: 17px;
}
.defaultIcons {
  color: red;
}
.writeReview {
  display: flex;
  align-items: center;
}
.borders {
  border-radius: 6px;
  background-color: white;
  margin: 10px;
}
.borderX {
  border-end-start-radius: 6px;
  border-end-end-radius: 6px;
}
.tabs {
  margin: 0px 10px;
  background-color: white;
  font-size: 1.2rem;
  font-weight: 600;
  color: gray;
  display: flex;
}
.tab {
  padding: 20px 15px 20px 15px;
}
.tab:hover {
  color: white;
  background-color: var(--color-primary);
  cursor: pointer;
}
.activeTab {
  color: white;
  background-color: var(--color-primary);
}
.overview {
  padding: 0px;
}
.overviewSection {
  margin-top: 20px;
}
.overviewCol {
  font-weight: 500;
  padding: 20px;
  text-align: justify;
}
.overviewCol2 {
  padding: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.ratingCol {
  padding: 20px;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  line-height: 2.5rem;
  flex-wrap: wrap;
  margin: 10px;
}
.details {
  padding: 15px 20px;
}
.detailsHeading {
  margin: 0px;
}
.detail {
  padding-bottom: 10px;
}
.gridContainer {
  display: grid;
  grid-template-columns: 1fr; /* Default: 1 column (mobile view) */
  gap: 16px; /* Adjust spacing between grid items */
}
.gridCol {
  background-color: white;
  padding: 30px 18px;
  border-radius: 6px;
  margin-top: -10px;
}
.gridContainer div {
  background-color: #f6f6f6;
  padding: 20px;
  text-align: left;
  border-radius: 6px;
  position: relative;
}
.gridContainer div h5 {
  color: var(--color-primary);
}
.gridSvg {
  font-size: 22px;
  color: var(--color-primary);
  margin-right: 5px;
}
.ratingDiv11 {
  font-size: 20px;
  font-weight: 700;
}
.ratingDiv21 {
  display: flex;
  gap: 10px;
  align-items: baseline;
}
.ratingDiv211 {
  font-size: 1rem;
  font-weight: 500;
  width: 160px;
  display: flex;
  gap: 3px;
  align-items: center;
}
.ratingDiv12 {
  font-size: 4rem;
  display: flex;
  gap: 10px;
  align-items: center;
}
.ratingDiv2 {
  display: flex;
  flex-direction: column;
  line-height: 2rem;
  justify-content: center;
}
.ratingDiv32 {
  font-size: 3rem;
}
.ratingDiv212 {
  font-size: 1rem;
  font-weight: 700;
}
.ratingDiv221 {
  font-size: 1rem;
  font-weight: 500;
  width: 160px;
  display: flex;
  align-items: baseline;
  gap: 3px;
}

.nameInDetailsInner {
  font-size: 1.875rem;
  font-weight: 500;
  color: black;
  padding-top: 20px;
}
.allDetails {
  color: gray;
  cursor: default;
  margin: 0px;
  gap: 15px;
}
.detailDiv {
  padding-bottom: 15px;
}
.detailHeading {
  font-size: 1.5rem;
}
.icon {
  width: 40px;
  transition: all 120ms ease-out;
}
.icon:hover {
  z-index: 2;
  transform: scale(1.2);
}
.iconDiv {
  display: flex;
  gap: 5px;
  padding-top: 10px;
}
.scrollLink {
  text-decoration: none !important ;
  color: var(--color-primary);
}
.scrollLink:hover {
  color: white !important;
}
.allStars {
  font-size: large;
}
.card {
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.05);
}

.card .card-img-top {
  height: 300px;
}
.noImagesMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 24px;
  color: gray;
  font-weight: 500;
  text-align: center;
  padding: 60px;
}
.title {
  border: 1px solid var(--color-primary);
  width: 100%;
  outline: none;
  padding: 5px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.noReviews {
  display: flex;
  font-size: 3rem;
  font-weight: 500;
  padding: 50px 20px;
  justify-content: center;
  color: grey;
  background-color: white;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 6px;
}
.spinnerDiv {
  display: flex;
  justify-content: center;
  padding: 10rem 0px;
}
.signInLoader {
  font-size: 15px;
  width: 24px !important;
  height: 24px !important;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .companyName,
  .noReviews,
  .nameInDetailsInner {
    font-size: 2rem;
  }
  /* .noReviews{
    font-size: 2rem;
  } */
  .tabs {
    margin: 0px;
    font-size: 0.8rem;
    flex-wrap: wrap;
  }
  .profileRow {
    text-align: center;
  }
  .writeReview {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .profilebtns {
    padding: 40px 15px;
  }
}
@media (min-width: 768px) {
  .gridContainer {
    grid-template-columns: repeat(3, 1fr); /* 2 columns */
  }
}
