.containerX {
  margin: 80px 0px 0 0;
  padding-bottom: 4rem;
  background-color: var(--color-primary-with-opacity);
}
.main {
  padding: 0px 50px;
  margin: 0px 80px;
  min-height: 26rem;
}
.bg-none {
  background: none !important;
}
.icon-right {
  position: relative;
  left: 15px;
}
.icon-left {
  position: relative;
  left: 15px;
}
.title {
  padding: 4rem 0 0 0px !important;
  display: flex;
  font-size: 1.75rem;
  font-weight: 700 !important;
  justify-content: center;
  margin-bottom: 40px;
  font-family: "Playfair Display", serif !important;
}
.outer {
  padding: 0px 30px;
  height: auto !important;
}
.slideOne {
  background-color: unset !important;
}
.oneCard {
  padding: 10px;
  margin: 10px;
  background-color: white;
  border-radius: 10px;
  text-align: left;
}
.imgDiv {
  width: 110px !important;
  height: 110px;
  padding: 0;
  margin-left: 15px;
}
.outerDiv {
  width: 30%;
}
.img {
  border-radius: 50%;
}
.btnDiv {
  padding-top: 10px;
}
.innerDiv {
  margin-top: 1rem;
  margin-left: -9px;
}

.btnDiv {
  padding-top: 10px;
}
.aceData {
  display: flex;
  flex-direction: column;
  line-height: 26px;
}
.largerFont {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75;
  cursor: pointer;
}
.mediumFont {
  font-size: medium;
  font-weight: 500;
  color: var(--color-primary);
  margin-bottom: 0.5rem;
}
.reviewSpan {
  font-size: medium;
  padding-left: 10px;
}
.stars {
  font-size: larger;
  line-height: inherit;
}
.categories {
  font-size: medium;
  padding: 0 2px;
}

@media screen and (max-width: 767px) {
  .main {
    margin: 0px;
    padding: 10px;
  }
  .outer {
    padding: 0;
  }
}
