.allCards {
  justify-content: space-between;
  padding-top: 80px;
  margin: 0px;
}
.oneCard {
  position: relative;
  padding: 50px 30px;
  background: var(--color-primary-with-opacity);
  border-radius: 20px;
  border: 2px black;
  margin-bottom: 100px;
}
.cardImgDiv {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cardImg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.heading {
  text-align: center;
  padding-top: 20px;
  font-size: 1.75rem;
  font-weight: 700 !important;
  font-family: "Playfair Display", serif !important;
}
.review {
  padding: 70px 0px 0px 30px;
}
.reviewBy {
  padding: 30px 30px 0px 30px;
  text-align: center;
}
.rating {
  padding: 0px 30px 0px 30px;
  text-align: center;
  font-size: xx-large;
  color: gold;
}
