.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  width: 100%;
}

.btnSecondary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.dropdownToggle::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.dropdownMenu {
  position: absolute;
  z-index: 9;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 5px;
  padding: 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown.show .dropdownMenu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdownItem {
  display: block;
  padding: 10px 20px;
  color: #333;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
  cursor: pointer;
}

.dropdownItem:last-child {
  border-bottom: none;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
  color: var(--color-primary);
}
.input {
  margin-right: 10px !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
}
/* Hide the default radio button */
input[type="radio"],
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* Create a custom radio button style */
input[type="radio"]::before,
input[type="checkbox"]::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid var(--color-primary); /* Custom color for radio button border */
  background-color: #fff;
  margin-right: 8px;
  vertical-align: middle;
}

/* Style the radio button when checked */
input[type="radio"]:checked::before,
input[type="checkbox"]:checked::before {
  background-color: var(
    --color-primary
  ); /* Custom color for checked radio button */
}
