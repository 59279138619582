@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap");

/* * {
  font-family: "Playfair Display", serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

body,
div,
p {
  font-family: inherit;
}
button,
input,
textarea {
  font-family: inherit;
}

code {
  font-family: "Bebas Neue", sans-serif;
}
:root {
  --swiper-navigation-color: rgb(67 204 147);
  --swiper-theme-color: rgb(67 204 147);
  --color-primary: rgb(45 90 69);
  --color-black: rgb(0, 0, 0);
  --color-white: rgb(255, 255, 255);
  --color-primary-with-opacity: rgb(67 204 147/10%);
  --color-primary-with-opacity-30: rgb(45 90 69/30%);
  --color-primary-with-opacity-50: rgba(67 204 147/ 50%);
  --color-primary-with-opacity-75: rgba(67 204 147/ 0.75);
}

input:-internal-autofill-selected {
  background-color: var(--color-primary-with-opacity-75) !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: var(--color-primary-with-opacity-75) !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.form-control.is-invalid {
  background-image: none !important;
}

a:hover {
  color: var(--color-primary) !important;
}
.rec.rec-arrow-left:hover:enabled,
.rec.rec-arrow-right:hover:enabled,
.rec.rec-arrow-left:focus:enabled,
.rec.rec-arrow-right:focus:enabled {
  background-color: var(--color-primary);
}
.rec.rec-arrow-left,
.rec.rec-arrow-right {
  background-color: var(--color-primary-with-opacity);
}
.rec.rec-pagination {
  display: none;
}
.container {
  margin-bottom: 40px;
  margin-top: 20px;
}

/*  */
.swiper-slide {
  background-color: none;
}
.splide .splide__pagination {
  display: none;
}
.splide .splide__arrow {
  background-color: rgb(67 204 147 / 0%);
}
.splide .splide__arrow svg {
  width: 2rem;
  height: 2rem;
  /* fill: var(--color-primary); */
}
svg {
  height: 100%;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  padding: 12px !important;
  padding-left: 5px !important;
}
.react-datepicker__close-icon::after {
  background-color: var(--color-primary) !important;
  padding: 0px 0px 2px 1px !important;
}
.react-datepicker__view-calendar-icon input {
  border: 1px solid black;
  border-radius: 6px;
  padding: 6px 20px 5px 25px !important;
}
