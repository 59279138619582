.containerX {
  max-width: 600px;
  margin: 50px auto;
  border-radius: 6px;
  padding-top: 20px;
  position: relative;
  background-color: white;
}
.backgroundColor {
  background-color: #eaeaea;
}
.backGround {
  background-color: white;
  position: sticky !important;
  top: 80px;
  z-index: 2;
  border-top: 1px solid var(--color-primary);
  border-bottom: 1px solid var(--color-primary);
}
.containerY {
  padding: 0px;
  margin: 0px auto;
}
.borderX {
  border-end-start-radius: 6px;
  border-end-end-radius: 6px;
}
.tabs {
  background-color: white;
  font-size: 1.2rem;
  font-weight: 600;
  color: gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tab {
  padding: 10px;
}
.tab:hover {
  color: white;
  background-color: var(--color-primary);
  cursor: pointer;
}
.activeTab {
  color: white;
  background-color: var(--color-primary);
}
.imgDiv {
  display: flex;
  justify-content: center;
  padding: 0px 100px;
}
.img {
  border-radius: 50%;
  width: 350px;
  height: 350px;

  object-fit: cover;
  object-position: center;
}
.infoDiv {
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.info {
  height: 40px;
  align-items: center;
  display: flex;
  position: relative;
}
.outerDiv {
  padding: 24px 10px;
}
.editing {
  position: absolute;
  right: 5px;
  top: 0px;
  font-size: 24px;
  background-color: white;
  width: 35px;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
}
.infoDivOnEditing {
  justify-content: flex-end;
}
.floatingEye {
  position: absolute;
  right: 35px;
  font-size: 24px;
  top: 0px;
}
.reviewHeading {
  font-size: 3rem;
  font-weight: 500;
  background-color: white;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
}
.categoryList {
  display: flex;
  align-items: flex-end;
  font-size: 0.9rem;
  gap: 5px;
  flex-wrap: wrap;
}
.categories {
  color: var(--color-primary);
  text-decoration: underline;
  cursor: pointer;
}
.categories:hover {
  color: green;
}
.dynamicRow {
  background-color: white;
  padding: 20px 9px 9px 4px;
  border-radius: 12px;
  margin: 10px 0px;
}

.title {
  font-size: 1.5rem;
  font-weight: 700;
}
.discription {
  font-size: 1.2rem;
  line-height: 24px;
  padding: 10px 0px;
}
.data {
  padding: 10px 0px;
  font-size: 1.2rem;
}
.bookmarkData {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.bookmarkPhoto {
  display: flex;
  padding-top: 10px;
}
.profilePhoto {
  border-radius: 6px;
  object-fit: cover;
}
.companyName {
  font-size: 1.5rem;
  font-weight: 600;
}

.companySmallDetails {
  font-size: 0.8rem;
  display: flex;
  gap: 12px;
}
.verifyPhoto {
  border-radius: 12px;
}
.btns {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.firstBtn {
  margin-bottom: 20px;
}
.btnDiv {
  margin: auto 5px auto 0px;
}
.customBtn {
  background-color: var(--color-primary);
  color: white;
  cursor: not-allowed;
}
.redBtn {
  background-color: red;
  color: white;
  cursor: not-allowed;
}
.noReviews {
  background-color: white;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 20px;
  color: gray;
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
  padding: 60px;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .noReviews {
    height: 100px;
    font-size: 20px;
  }
  .tabs {
    font-size: 0.6rem;
  }
  .btnDiv {
    margin: 1px 5px 1px 0px;
  }
  .btn {
    height: 30px !important;
  }
  .img {
    width: 200px;
    height: 200px;
  }
  .companySmallDetails {
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 5px;
    gap: 0px;
    flex-direction: column;
  }
  .bookmarkData {
    justify-content: center;
    padding-bottom: 15px;
  }
  .tab {
    padding: 10px 7px;
  }
}
@media screen and (min-width: 991px) and (max-width: 1199px) {
  .bookmarkData {
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 991px) {
  .companySmallDetails {
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 5px;
    gap: 0px;
    flex-direction: column;
  }
  .bookmarkData {
    justify-content: center;
    padding-bottom: 15px;
    text-align: center;
  }
}
