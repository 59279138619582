.customButton {
  background-color: transparent;
  width: 100%;
  height: 100%;
  min-width: max-content;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  border-radius: 6px;
  padding: 6px 14px;
  font-size: 1rem;
  transition: color 0.15s ease-in-out, background-color 0.45s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.customButton:hover {
  color: var(--color-white);
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.customButton:focus {
  color: var(--color-white);
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  outline: 0;
}
.customRedButton {
  background-color: transparent;
  width: 100%;
  height: 100%;
  min-width: max-content;
  border: 1px solid red;
  color: red;
  border-radius: 6px;
  padding: 6px 14px;
  font-size: 1rem;
  transition: color 0.15s ease-in-out, background-color 0.45s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.customRedButton:hover {
  color: var(--color-white);
  background-color: red;
  border-color: red;
}
.customRedButton:focus {
  color: var(--color-white);
  background-color: red;
  border-color: red;
  outline: 0;
}

@media (max-width: 960px) {
  .customButton {
    width: 250px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}
