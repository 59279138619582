.background {
  background-color: var(--color-primary-with-opacity);
}
.heading {
  display: flex;
  font-size: 1.75rem;
  font-weight: 700 !important;
  justify-content: center;
  margin-bottom: 40px;
  padding-top: 24px;
  font-family: "Playfair Display", serif !important;
}
.gridcontainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px; /* Equivalent to gap-8 */
}
.oneGrid {
  text-align: center;
  animation: fadeUp 0.5s ease-in-out;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  padding: 15px;
}
.oneGrid:hover {
  transform: translateY(-8px);
}
.gridSpan {
  padding: 15px;
  width: 4rem;
  background-color: var(--color-primary-with-opacity);
  border-radius: 50%;
  height: 4rem;
  margin-bottom: 20px;
}
.gridSvg {
  font-size: 25px;
  color: var(--color-primary);
}
.title {
  font-size: 1.25rem;
}
.desc {
  color: grey;
  font-size: 0.9rem;
}

@media (min-width: 768px) {
  .gridcontainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .containerDiv {
    margin-left: 0;
    margin-right: 0;
  }
}
