.priceCard {
  padding: 35px 35px 35px;
  border: none;
  transition: all 0.6s;
  background-color: rgb(250 250 250);
}
.priceCard:hover {
  transform: translateY(-10px);
  background-color: var(--color-primary-with-opacity-30);
  box-shadow: 0 2px 40px 0 rgba(205, 205, 205, 0.55);
}

.priceCardBenifits {
  font-size: 14px;
}
.selectedPlan {
  background-color: var(--color-primary);
  color: white;
  cursor: not-allowed !important;
}
