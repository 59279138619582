.selected {
  color: "red";
}
.heading {
  font-size: 2rem;
}
.customMargin {
  margin: 10px 0px;
}
.starRow {
  display: flex;
  gap: 10px;
  line-height: 24px;
}
.btn {
  margin-top: 40px;
  text-align: center;
}
.stars {
  font-size: 35px;
  cursor: pointer;
  transition: fill 0.5s ease;
}
