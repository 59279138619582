.dynamicCard {
  display: flex;
  flex-direction: row;
  padding: 20px;
  margin: 10px 0px;
  border: none;
}
.cardBody {
  padding: 0px;
}
.cardTitle {
  font-size: 2rem;
  line-height: 31px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
}
.cardImg {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}
.cardText {
  line-height: 2rem;
  text-align: justify;
}
.readMore {
  color: var(--color-primary);
  cursor: pointer;
}
.readMore:hover {
  text-decoration: underline;
}
.stars {
  line-height: 0px;
  gap: 3px;
  display: flex;
  font-size: 1.5rem;
}
.writeReview {
  margin: auto;
}
.requestedBtn {
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-primary);
  border-radius: 6px;
  padding: 6px 14px;
  font-size: 1rem;
  color: var(--color-white);
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  cursor: not-allowed !important;
}
.details {
  font-weight: 700;
}

/*  */

/* For mobile view */
@media (max-width: 767px) {
  .dynamicCard {
    display: flex;
    flex-direction: column;
  }
  .upperColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 15px;
  }
  .lowerColumn {
    padding: 1rem;
  }
  .cardBody {
    text-align: center;
  }
  .cardTitle {
    text-align: center;
    font-size: 1.5rem;
  }
  .stars {
    justify-content: center;
  }
}

/* For desktop view */
@media (min-width: 768px) {
  .dynamicCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .upperColumn {
    display: flex;
    align-items: flex-start;
    gap: 15px;
  }
  .lowerColumn {
    display: flex;
    align-items: center;
  }
}
