.customInputField {
  border: 1px solid rgb(127 127 127);
  border-radius: 6px;
  width: 100%;
  padding: 5px 10px;
  position: relative;
  font-size: 1.3rem;
}
.customInputField:focus-visible,
.customInputField:focus {
  border: 1px solid rgb(95, 183, 130);
  outline: none;
  box-shadow: 0 0 2px rgb(95, 183, 130);
}
.empty,
.empty:focus-visible,
.empty:focus {
  border: 1px solid red;
  outline: none;
  box-shadow: 0 0 2px red;
}
.spinnerDiv {
  display: flex;
  justify-content: center;
  padding: 10rem 0px;
}
.signInLoader {
  font-size: 15px;
  width: 24px !important;
  height: 24px !important;
  text-align: center;
}
.nearText {
  align-items: center;
  display: flex;
  justify-content: space-around;
  font-size: 1.3rem;
}
.locationSection {
  padding: 15px 0px;
  align-items: center;
}
.totalResultSection {
  background-color: white;
  display: flex;
}
.totalResultSectionCol {
  padding: 20px;
  font-size: 1.2rem;
  cursor: pointer;
}
.mainDynamicSection {
  padding: 15px 0px;
}
.locationSectionOuter {
  background-color: #f7f7f7;
}
.totalResultSectionOuter {
  background-color: white;
}
.mainDynamicSectionOuter {
  background-color: #f7f7f7;
}
.container {
  margin: 0px auto 0px auto;
}
.cardUpperDiv {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}
.upperDiv {
  display: flex;
  gap: 10px;
  padding-bottom: 6px;
}
.dynamicHeading {
  font-size: 2.5rem;
  margin-bottom: 10px;
  padding: 0px;
}
.dynamicSectionPhrase {
  padding-bottom: 40px;
  padding-left: 0px;
}
.dynamicRow {
  background-color: white;
  padding: 20px 9px 9px 9px;
  border-radius: 12px;
  margin-bottom: 20px;
}
.profilePhoto {
  border-radius: 12px;
  object-fit: cover;
}
.verifiedPhotoDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.verifyPhoto {
  border-radius: 12px;
}
.firstBtn {
  margin-bottom: 20px;
}
.categoryList {
  display: flex;
  align-items: flex-end;
  font-size: 0.9rem;
  gap: 5px;
  flex-wrap: wrap;
}
.companyName {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 19px;
  padding-bottom: 5px;
}
.verify {
  display: flex;
  justify-content: center;
  align-items: center;
}
.companySmallDetails {
  font-size: 0.8rem;
  display: flex;
  gap: 12px;
}
.adDiv {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.ad {
  color: #b8b8b8;
  border: 1px solid #b8b8b8;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}
.searchBtn {
  padding-right: 22px;
}
.dropdownBtn {
  margin: 0px 20px;
}
.searchSpan {
  padding: 8px 15px;
  border-radius: 6px;
  background-color: #73cf92;
  color: white;
}
.active {
  border-bottom: 1px solid black;
}
.error {
  font-size: 2rem;
  color: #4f4f4f;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 0px;
}
.errorSuggestion {
  padding-bottom: 8rem;
  padding-left: 0px;
  font-size: 1.1rem;
}
.errorSuggestionHeading {
  color: #4f4f4f;
  padding: 0px;
}
.errorSuggestionList {
  padding: 0px 35px;
}
.categories {
  color: var(--color-primary);

  cursor: pointer;
}
.categories:hover {
  color: green;
  text-decoration: underline;
}
.bothBtns {
  margin: auto;
  justify-content: space-evenly;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .dropdownBtn {
    margin: 0;
  }
  .totalResultSectionCol {
    padding: 20px 10px;
    font-size: 0.9rem;
  }
  .mainDynamicSection {
    margin: 0px;
  }
  .dynamicHeading {
    font-size: 1.5rem;
  }
  .error {
    font-size: 1rem;
  }
}
