.mainDiv {
  position: relative;
  overflow: hidden;
  min-height: 700px;
  height: 92vh;
  /* Adjust this value to control the minimum height of the component */
}

.picture {
  background-image: url("https://media.istockphoto.com/id/1170478532/photo/the-house-wall-gets-new-color.jpg?s=612x612&w=0&k=20&c=i-5uV_K3aOMnVDqYR-s0Wy-FmhVipwtrzSeovDw0IKY=");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3; /* Adjust this value to control the opacity of the background image */
  z-index: -1;
}

.header {
  font-size: 3.75rem;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  position: absolute;
  top: 50%; /* Center the header vertically */
  left: 50%; /* Center the header horizontally */
  transform: translate(-50%, -50%);
}
.mainHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-family: "Playfair Display", serif;
}

.headerSpan {
  background: var(--color-primary-with-opacity-30);
  border-radius: 10px;
  line-height: 60px;
  color: var(--color-primary);
  margin: 5px;
  padding: 0px 5px 0 5px;
}

.subHeader {
  font-size: 1.125rem; /* Adjust this value for the subheader font size */
  text-align: center;
  margin-top: 20px;
  font-weight: 400;
  color: grey;
}
.searchIcon {
  position: absolute;
  z-index: 99;
  font-size: large;
  left: 15px;
  color: lightgrey;
}

.outerButton {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.inputGroup {
  margin-top: 60px;
  height: 50px;
}
.inputField {
  font-size: 1rem;
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
  padding-left: 40px;
}
.inputField:focus,
.inputField:focus-visible {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: var(--color-primary);
  outline: 0;
  box-shadow: 0 0 20px 0.25rem var(--color-primary-with-opacity-30);
}
.inputBtn {
  font-size: 1rem;
  width: 130px;
  border-radius: 25px;
}

/* Add additional media queries for responsiveness */
@media (max-width: 768px) {
  .header {
    font-size: 4rem;
    width: 20rem;
  }
  .mainHeader {
    font-size: 2.25rem !important;
  }

  .subHeader {
    font-weight: 300;
    color: grey;
  }
  .inputField {
    font-size: 1rem;
  }
  .inputBtn {
    font-size: 1rem;
    width: auto;
  }
}
