.navbar {
  background: var(--color-white);
  height: 80px;
  display: flex;
  justify-content: center !important;
  align-items: center;
  font-size: 1.2rem;
  position: sticky !important;
  top: 0px;
  z-index: 2;
  border-bottom: 1px solid lightgrey;
}

.navbar-logo {
  color: var(--color-primary);
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
}

.fa-react {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  list-style: none;
  text-align: center;
  width: 80vw;
  align-items: center;
  justify-content: end;
  margin: 0px;
  padding-left: 0px;
  gap: 10px;
}

.nav-links {
  color: grey;
  text-decoration: none;
}

.fa-bars {
  color: var(--color-white);
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.item {
  position: relative;
  padding: 0.3rem 2rem;
}

.itemSpan {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--color-primary);
  left: 0px;
  top: 0px;
  opacity: 0.1;
  border-radius: 6px;
}
.logo {
  font-size: 18px;
  font-weight: 700;
}
.desptopImg {
  display: block;
}
.mobileImg {
  display: none;
}

.newTasks {
  position: absolute;
  right: 0px;
  top: -10px;
  background-color: var(--color-primary);
  color: white;
  width: 25px;
  height: 25px;
  font-size: 18px;
  border-radius: 50%;
}

@media (max-width: 960px) {
  .navbar {
    position: relative;
  }
  .item {
    padding: 10px;
    width: 85%;
  }
  .desptopImg {
    display: none;
  }
  .mobileImg {
    display: block;
    margin-top: -13px;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: var(--color-white);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 10;
    padding: 0px;
  }

  .nav-links {
    text-align: center;
    padding: 1rem;
    width: 100%;
    display: table;
  }

  .navbar-logo {
    position: absolute;
    top: 0px;
    left: 0;
    margin-left: 0px;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    color: var(--color-white);
    display: block;
    position: absolute;
    top: -12px;
    right: 4px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: var(--color-white);
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #4ad9e4;
    text-decoration: none;
    color: var(--color-white);
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: var(--color-white);
    color: #6568f4;
    transition: 250ms;
  }
}
