.title {
  border: 1px solid var(--color-primary);
  width: 100%;
  outline: none;
  padding: 5px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edit {
  cursor: pointer;
  display: flex;
  position: relative;
  margin-bottom: 10px;
}
.icon {
  position: absolute;
  right: 11px;
  font-size: 22px;
}
.navigationWrapper {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
}

.card {
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.05);
}

.card .card-img-top {
  height: 300px;
}

.noImagesMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 24px;
  color: gray;
  /* font-size: 3rem; */
  font-weight: 500;
  text-align: center;
  padding: 60px;
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
