.img {
  cursor: pointer;
}
.noImagesMessage {
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
  padding: 60px;
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.card {
  transition: transform 0.3s ease-in-out;
  will-change: transform;
}

.card:hover {
  transform: scale(1.05);
}

.card .card-img-top {
  height: 300px;
}
