.divv {
  padding: 200px;
  margin-top: 100px;
  background-color: antiquewhite;
}
.active {
  display: block;
}
.inactive {
  display: none;
}
.main {
  background-color: var(--color-primary-with-opacity);
  border-radius: 20px;
  margin: 60px auto 100px;
  max-width: 600px;
  padding: 20px 30px;
}
.heading {
  border-radius: 20px;
  margin-bottom: 20px;
  font-size: 20px;
}
.btn {
  margin-top: 30px;
}
.mainHeading {
  font-family: "Oswald", sans-serif;
  font-size: 2rem;
  font-weight: 500;
}
.headingDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 75px;
}
.smallHeading {
  font-weight: 300;
}
.bothIcons {
  display: flex;
  gap: 70px;
  padding: 20px 0px 40px;
}
.icon {
  display: flex;
  align-items: center;
  border: 5px solid black;
  border-radius: 50%;
  padding: 25px;
  width: 100%;
  cursor: pointer;
}
/* .icon:hover {
  fill: var(--color-primary);
  color: var(--color-primary);
  border: 5px solid var(--color-primary);
} */
.selected {
  fill: var(--color-primary);
  border: 5px solid var(--color-primary);
}
.radioDiv {
  margin: 25px 0px;
}
.pointer {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .bothIcons {
    gap: 20px;
    justify-content: space-evenly;
  }
  .iconHome {
    padding: 6px;
    width: 110px;
  }
  .iconBuilding {
    padding: 18px !important;
    width: 110px;
  }
  .main {
    padding: 20px;
  }
}
