.main {
  background-color: var(--color-primary-with-opacity);
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.innerDiv {
  padding: 30px;
  text-align: center;
}
.heading {
  font-size: 1.75rem;
  font-weight: 700 !important;
  font-family: "Playfair Display", serif !important;
}
.subheading {
  font-size: 1.25rem;
  color: grey;
  font-weight: 300;
  margin-bottom: 2rem;
}
.btnDiv {
  width: 15%;
  margin: auto;
  margin-top: 25px !important;
}
